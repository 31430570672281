/* global gapi */
import * as React from "react"
import {useEffect, useState} from "react"
import styled, {css} from "styled-components";
import {Formik} from 'formik';
import '../../app.sass';
import './index.sass';
import * as three from "three";
import {BoxGeometry} from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import vertexShader from './particle-vertex-shader.glsl';
import fragmentShader from './particle-fragment-shader.glsl';
import {Helmet} from "react-helmet";
import {animated, useSpring} from "@react-spring/web";
import icon from '../images/logo.svg';
import * as Yup from 'yup';
import Airtable from "airtable";
import {toast, Toaster} from "react-hot-toast";
import {graphql, useStaticQuery} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {LightBulbIcon, LightningBoltIcon} from "@heroicons/react/outline";

declare global {
    interface Window {
        gapi: any
    }
}

interface HeroSectionModel {
    title: string,
    description: any
}

interface FeaturesSectionModel {
    title: string,
    description: any,
    link?: string
}

const deviceSizes = {
    smPhone: '320px',
    phoneOnly: '599px',
    tabletPortraitUpMin: '600px',
    tabletPortraitUpMax: '899px',
    tabletLandscapeUpMin: '900px',
    tabletLandscapeUpMax: '1119px',
    mdpiLaptopMin: '1120px',
    mdpiLaptopMax: '1280px',
};

const devices = {
    smallPhone: `(max-width: ${deviceSizes.smPhone})`,
    phone: `(max-width: ${deviceSizes.phoneOnly})`,
    tabletPortrait: `(min-width:  ${deviceSizes.tabletPortraitUpMin}) and (max-width: ${deviceSizes.tabletPortraitUpMax})`,
    tabletLandscape: `(min-width:  ${deviceSizes.tabletLandscapeUpMin}) and (max-width: ${deviceSizes.tabletLandscapeUpMax})`,
    mdpiLaptop: `(min-width:  ${deviceSizes.mdpiLaptopMin}) and (max-width: ${deviceSizes.mdpiLaptopMax})`
};

// styles
const PageContainer = styled.main`
  position: relative;
  width: 100%;
  height: 100%;
`;

// @ts-ignore
const Button = styled.button`
  display: flex;
  flex-direction: row;
  gap: 0.55rem;
  align-items: center;
  border: 2px solid #dfdfdf;
  color: #000000;
  background: #efefef !important;
  white-space: nowrap;
  width: fit-content;
  padding: .8rem 1.8rem;
  border-radius: 6px;
  ${({variant}) => variant == 'large' && css`
    padding: 1rem 2rem;
    font-weight: 600;
    height: 65px;
    line-height: 0.85rem;
    font-size: 1.15rem;`}
`;

const BackgroundParticlesContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -2;
`;

const BackgroundGrid = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  //pointer-events: none;
`;

const BackgroundGridSeparator = styled.div`
  width: 25%;
  border-left: 2px dashed rgba(0, 0, 0, 0.05);
  height: 100%;
  @media ${devices.phone} {
    visibility: hidden;
  }
`;

const HeroSection = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px 0 70px;

  @media ${devices.smallPhone} {
    width: 90% !important;
    padding: 40px 0;
  }
  @media ${devices.phone} {
    width: 85%;
    padding: 30px 0;
  }
  @media ${devices.tabletPortrait} {
    width: 82%;
    padding: 40px 0;
  }
  @media ${devices.tabletLandscape} {
    width: 66%;
    padding: 40px 0;
  }
`;

const FeaturesSection = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  margin: 0 auto;
  background: #f9fff6;
  padding: 4.5rem 0;

  @media ${devices.smallPhone} {
    padding: 40px 0;
  }
  @media ${devices.phone} {
    padding: 30px 0;
  }
  @media ${devices.tabletPortrait} {
    padding: 40px 0;
  }
  @media ${devices.tabletLandscape} {
    padding: 40px 0;
  }
`;

const ContentBlock = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media ${devices.smallPhone} {
    width: 90% !important;
  }
  @media ${devices.phone} {
    width: 85%;
  }
  @media ${devices.tabletPortrait} {
    width: 82%;
  }
  @media ${devices.tabletLandscape} {
    width: 66%;
  }
`;

const ContentSection = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  margin: 0 auto;
  background: white;
  padding: 5rem 0;

  @media ${devices.smallPhone} {
    padding: 40px 0;
  }
  @media ${devices.phone} {
    padding: 30px 0;
  }
  @media ${devices.tabletPortrait} {
    padding: 40px 0;
  }
  @media ${devices.tabletLandscape} {
    padding: 40px 0;
  }
`;

// markup
function IndexPage() {
    if (typeof window === "undefined") return null;

    const isBrowser = () => typeof window !== "undefined";

    if (!isBrowser()) {

    }

    let airtableBase: Airtable.Base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API_KEY}).base('appY4c8UBRO7sWuCg');

    const initialWaitListFormValues = {
        email: ''
    }

    const waitListFormSchema = Yup.object().shape({
        email: Yup.string().email('Kindly enter a valid email.').required('Please enter your email.'),
    });

    const [heroSection, setHeroSection] = useState<HeroSectionModel | undefined>();
    const [featuresSection, setFeaturesSection] = useState<FeaturesSectionModel[]>([]);
    const contentful_landing_page_content = useStaticQuery(graphql`
        query LandingPageContent {
            allContentfulLandingHeroSection {
                nodes {
                    title
                    description {
                        raw
                    }
                }
            }
            allContentfulFeaturesSection {
                nodes {
                    description {
                        raw
                    }
                    title
                }
            }
        }
    `);

    useEffect(() => {
        if (contentful_landing_page_content) {
            const contentfulHeroSection: HeroSectionModel[] = contentful_landing_page_content.allContentfulLandingHeroSection.nodes;
            const contentfulFeaturesSection: FeaturesSectionModel[] = contentful_landing_page_content.allContentfulFeaturesSection.nodes;

            if (contentfulHeroSection) {
                const heroSectionFormatted = {
                    title: contentfulHeroSection[0].title,
                    description: JSON.parse(contentfulHeroSection[0].description.raw)
                }
                setHeroSection(heroSectionFormatted);
            }

            if (contentfulFeaturesSection) {
                const featuresSectionFormatted = contentfulFeaturesSection.map(featureSectionNode => {
                    return {
                        title: featureSectionNode.title,
                        description: JSON.parse(featureSectionNode.description.raw)
                    }
                })

                setFeaturesSection(featuresSectionFormatted);
            }
        }


    }, [contentful_landing_page_content])


    const LandingPageAnimations = {
        dropIn: {
            config: {
                duration: 2000, easing: (t: number) => {
                    return -(Math.cos(Math.PI * t) - 1) / 2;
                }
            },
            to: {opacity: 1, y: '0'},
            from: {opacity: 0, y: '-20%'}
        }
    }

    const sectionEntryAnimation = useSpring(LandingPageAnimations.dropIn);

    let renderer: any;
    let scene: any;
    let camera: any;

    let particles: any;

    const PARTICLE_SIZE = 20;

    let raycaster: any;
    let intersects: any;
    let pointer: any;
    let INTERSECTED: any;

    function init() {

        const container = document.getElementById('backgroundParticlesContainer');

        scene = new three.Scene();

        camera = new three.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
        camera.position.z = 250;

        const boxWidth = window.innerWidth >= 1920 ? 500 : 350;
        const boxDepth = window.innerWidth >= 1920 ? 600 : 480;
        let boxGeometry = new three.BoxGeometry(boxWidth, 500, boxDepth, 30, 30, 30);

        boxGeometry.deleteAttribute('normal');
        boxGeometry.deleteAttribute('uv');

        boxGeometry = BufferGeometryUtils.mergeVertices(boxGeometry) as BoxGeometry;

        //

        const positionAttribute = boxGeometry.getAttribute('position');

        const colors: any[] = [];
        const sizes: any[] = [];

        const color = new three.Color();

        for (let i = 0, l = positionAttribute.count; i < l; i++) {

            color.setHSL((210 / 360), (14 / 100), (83 / 100));
            color.toArray(colors, i * 3);

            sizes[i] = PARTICLE_SIZE * 0.5;

        }

        const geometry = new three.BufferGeometry();
        geometry.setAttribute('position', positionAttribute);
        geometry.setAttribute('customColor', new three.Float32BufferAttribute(colors, 3));
        geometry.setAttribute('size', new three.Float32BufferAttribute(sizes, 1));

        //

        const material = new three.ShaderMaterial({
            uniforms: {
                color: {value: new three.Color(0xffffff)},
                pointTexture: {value: new three.TextureLoader().load('textures/disc.png')},
                alphaTest: {value: 0.9}
            },
            vertexShader,
            fragmentShader
        });

        //

        particles = new three.Points(geometry, material);
        scene.add(particles);

        //

        renderer = new three.WebGLRenderer({alpha: true});
        renderer.setClearColor(0x000000, 0);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        container?.appendChild(renderer.domElement);

        //

        raycaster = new three.Raycaster();
        pointer = new three.Vector2();

        //

        window.addEventListener('resize', onWindowResize);
        document.addEventListener('pointermove', onPointerMove);

    }

    function onPointerMove(event: any) {

        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

    }

    function backgroundAnimation() {
        requestAnimationFrame(backgroundAnimation);
        render();
    }

    function render() {

        particles.rotation.x += 0.0005;
        particles.rotation.y += 0.001;

        const geometry = particles.geometry;
        const attributes = geometry.attributes;

        raycaster.setFromCamera(pointer, camera);

        intersects = raycaster.intersectObject(particles);

        if (intersects.length > 0) {

            if (INTERSECTED != intersects[0].index) {

                attributes.size.array[INTERSECTED] = PARTICLE_SIZE;

                INTERSECTED = intersects[0].index;

                attributes.size.array[INTERSECTED] = PARTICLE_SIZE * 1.25;
                attributes.size.needsUpdate = true;

            }

        } else if (INTERSECTED !== null) {

            attributes.size.array[INTERSECTED] = PARTICLE_SIZE;
            attributes.size.needsUpdate = true;
            INTERSECTED = null;

        }

        renderer.render(scene, camera);
    }

    useEffect(() => {
        init();
        backgroundAnimation();
    }, []);


    async function airtableEmailSubmission(waitlist: { email: string }) {
        if (!(await emailExists(waitlist.email))) {
            await airtableBase.table('Emails').create([
                {
                    "fields": {
                        "Email": waitlist.email
                    }
                }
            ], function (err: any, records: any) {
                if (err) {
                    console.error(err);
                    return;
                }
                toast("You've been added to the waiting list!", {icon: '🎉'});
            });
        } else {
            toast("This email is already registered.", {icon: '✋'});
        }

    }

    async function emailExists(email: string): Promise<boolean> {

        return new Promise(async resolve => {
            await airtableBase.table('Emails').select({
                maxRecords: 1,
                view: 'Grid view',
                filterByFormula: `{Email}='${email}'`
            }).firstPage(function (err, records) {
                if (err) {
                    console.error(err);
                    toast("Oops, we encountered an issue. Try again later", {icon: '😭'});
                    return;
                }
                if (records?.length > 0) {
                    resolve(true);
                } else {
                    resolve(false)
                }
            });
        });
    }

    /**
     * Redirects the user to the RFI Demo page
     * */
    function openRFIDemo() {
        window.location.href = 'http://www.demo.rfi-alert.com/';
    }

    return (
        <>
            <Helmet>
                <title>RFI Alert</title>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                    rel="stylesheet"/>

                <script src="https://kit.fontawesome.com/5a0d7d87ec.js" crossOrigin="anonymous"></script>
                <script src="https://apis.google.com/js/api.js"></script>
            </Helmet>

            <PageContainer>
                <BackgroundParticlesContainer>
                    <div id={'backgroundParticlesContainer'}/>
                </BackgroundParticlesContainer>
                <BackgroundGrid>
                    {[1, 2, 3, 4, 5, 6].map((column, index) => {
                        return <BackgroundGridSeparator key={column + index}/>
                    })}
                </BackgroundGrid>
                <animated.div style={useSpring(sectionEntryAnimation)}>
                    <HeroSection>
                        <nav className="container mb-2 md:mb-12 flex flex-wrap justify-between items-center mx-auto">
                            <div className="nav-brand">
                                <img className="icon" src={icon}/>
                            </div>
                            <div>
                                <button data-collapse-toggle="mobile-menu" type="button"
                                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                        aria-controls="mobile-menu-2" aria-expanded="false">
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                              clip-rule="evenodd"></path>
                                    </svg>
                                    <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
                                    <ul className="flex flex-col mt-4 items-center md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium">
                                        <li>
                                            <a href="#"
                                               className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-lime-600 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Features</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                               className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-lime-600 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Blog</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                               className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-lime-600 dark:hover:text-white md:dark:hover:bg-transparent">Contact
                                                Us</a>
                                        </li>
                                        <li>
                                            <button type="button" onClick={openRFIDemo}
                                                    className="inline-flex items-center px-4 py-2 border shadow-sm text-base font-medium rounded-md text-white bg-violet-500 hover:bg-violet-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600">
                                                Try Demo
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <div className="hero-section my-2 pb-2 pt-5">
                            <p className="display-text text-gray-800 brand-subtitle">{heroSection?.title}</p>
                            {/*<hr className="content-divider"/>*/}
                            {heroSection?.description &&
                                <div
                                    className="body-text text-gray-700 product-description">{documentToReactComponents(heroSection.description)}</div>
                            }
                        </div>
                        <div className="wait-list">
                            <Formik initialValues={initialWaitListFormValues}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        setSubmitting(true);
                                        await airtableEmailSubmission(values);
                                        setSubmitting(false);
                                    }} validationSchema={waitListFormSchema}>
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting
                                      /* and other goodies */
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-control w-full">
                                            <input className={`opply-input -large ${errors.email && '-error'}`}
                                                   name="email"
                                                   type="email"
                                                   placeholder={"Enter your email"} onChange={handleChange}
                                                   onBlur={handleBlur} value={values.email}/>
                                            {
                                                errors.email && (<span className="hint -error">
                                                    {errors.email && touched.email && errors.email}
                                                </span>)
                                            }

                                        </div>
                                        <Button type="submit" variant="large">
                                            {isSubmitting ?
                                                <div className={"loading"}><i className="fas fa-spinner"></i>
                                                </div> : <><i className="fas fa-arrow-right"></i></>
                                            }
                                            Get Early Access
                                        </Button>
                                    </form>
                                )}
                            </Formik>

                        </div>
                    </HeroSection>
                    <FeaturesSection>
                        <ContentBlock>
                            <h4 className={'font-semibold text-4xl mb-8'}>What's in the box?</h4>
                            <section className={'features-section flex flex-col lg:flex-row gap-10'}>
                                {
                                    (featuresSection && featuresSection.length > 0) && featuresSection.map(featureSection => {
                                        return <div
                                            className={'feature bg-white overflow-hidden drop-shadow-xl rounded-lg py-7 px-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/2'}>
                                            <LightningBoltIcon className="h-10 w-10 text-slate-400  mb-2"/>
                                            <p className="font-semibold text-2xl text-slate-800 mb-1">{featureSection?.title}</p>
                                            <p className="text-lg">{documentToReactComponents(featureSection?.description)}</p>
                                        </div>
                                    })
                                }
                            </section>
                        </ContentBlock>
                    </FeaturesSection>
                    <ContentSection>
                        <ContentBlock>
                            <h4 className={'font-semibold leading-normal text-4xl mb-6'}>The Problems Of The Government Contracting
                                market.</h4>
                            <p className="text-2xl text-gray-700 font-extralight leading-relaxed mb-6">$1.1 trillion was the amount that, in 2021, the U.S.
                                Government spent
                                on goods and services,
                                ranging from paper clips and janitorial services to next-generation space shuttles and
                                sophisticated scientific research. </p>

                            <p className="text-2xl text-gray-700 font-extralight leading-relaxed mb-6">To that end, the U.S. Government has been calling
                                for
                                large enterprises as welllg as SMEs to provide such goods and services via contract
                                notices
                                published on SAM.GOV, the e-procurement System for Award Management. </p>
                            <p className="text-2xl text-gray-700 font-extralight leading-relaxed mb-6">Although the number of opportunities is abundant
                                with 1000-1500 new contract notices listed daily on SAM.GOV, the platform is notoriously
                                difficult for its users to approach the right opportunities due to:</p>
                            <ol className="text-2xl text-gray-700 font-extralight leading-relaxed mb-6">
                                <li>- Poor search relevance</li>
                                <li>- The lack of updates promptly for users</li>
                                <li>- The complete absence of effective strategies guiding new businesses to enter into
                                    this B2G market.
                                </li>
                            </ol>
                            <p className="text-2xl text-gray-700 font-extralight leading-relaxed mb-6">The
                                core services of RFI Alert are the remedies for such problems on SAM.GOV.</p>

                        </ContentBlock>
                    </ContentSection>

                    <div style={{width: '100%', padding: '20px 16px', dispay: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                        <p><strong>Contact Us:</strong> <a href={'emailto:hello@rfi-alert.com'}>hello@rfi-alert.com</a></p>
                    </div>
                </animated.div>
            </PageContainer>
            <Toaster/>
        </>
    )
}

export default IndexPage;
